import React, { useEffect, useState } from 'react'
import styles from './sidebar.module.scss'
import { NavLink, useNavigate } from 'react-router-dom'
import Logo from '../../../assets/images/logo-original-siyah-co-one.png'
import SegmentIcon from '@mui/icons-material/Segment'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import HomeIcon from '@mui/icons-material/Home'
import ProjectsIcon from '@mui/icons-material/Description'
import SettingsIcon from '@mui/icons-material/Settings'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import CircleIcon from '@mui/icons-material/Circle'
import { useGlobal } from '../../../context/GlobalContext'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled, alpha } from '@mui/system'
import { Badge, Button, ButtonBase, IconButton } from '@mui/material'
import PieChart from '../../Charts/pieChart'
import Freemium from '../../Freemium'
import useSetDefaultMenu from '../../../hooks/useSetDefaultMenu'
import FreeTrail from '../../FreeTrail'
import freeTrailPic from '../../../assets/images/images/Payment/freeTrailPic.svg'
import useGetConfig from '../../../hooks/useGetConfig'
import { Box, Link, Typography, Avatar, Menu, MenuItem } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import { useFirebase } from '@/firebase'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { setSettingsModal } from '@/store/navigation'
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    left: 80,
    top: -25,
    height: 50,
    width: 50,
    borderRadius: '50%',
    textAlign: 'center',
    padding: '0 4px',
  },
  '& .MuiBadge-badge:hover': {
    cursor: 'pointer',
  },
}))

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
}))

const CustomWidthTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 250,
    marginLeft: '25px',
  },
})

const Sidebar = () => {
  const navigate = useNavigate()
  const { time } = useGetConfig()
  useSetDefaultMenu()
  const [openModal, setOpenModal] = useState(false)
  const [openModalFreeTrail, setopenModalFreeTrail] = useState(false)
  const [openProjectModal, setOpenProjectModal] = useState(false)
  const [showPiChart, setShowPiChart] = useState(false)
  const [daysLeft, setDaysLeft] = useState(0)

  const { customer, user } = useSelector((state: RootState) => state.auth)
  const { projects } = useSelector((state: RootState) => state.projects)
  const { navigation, settingsModal } = useSelector(
    (state: RootState) => state.navigation
  )
  const { activeSubscriptionInfo } = useSelector((state: RootState) => state.ui)
  const { isCollapse, toggleIsCollapse } = useGlobal()

  const [helpCard, setHelpCard] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const { api } = useFirebase()
  const dispatch = useDispatch()

  const getIcon = (title: string) => {
    switch (title) {
      case 'Home':
        return <HomeIcon className={styles.Icon} />
      case 'Projects':
        return <ProjectsIcon className={styles.Icon} />
      case 'Settings':
        return <SettingsIcon className={styles.Icon} />
      case 'Billing':
        return <CreditCardIcon className={styles.Icon} />
      default:
        break
    }
  }

  const SettingModals = () => {
    let items = [
      {
        name: 'My Profile',
        modal: 0,
      },
      {
        name: 'Projects',
        modal: 1,
      },
      {
        name: 'Payment & Billing',
        modal: 2,
      },
      {
        name: 'Tickets',
        modal: 3,
      },
      {
        name: 'Notification',
        modal: 4,
      },
      {
        name: 'Security',
        modal: 5,
      },
      {
        name: 'Contact Us',
        modal: 6,
      },
    ]
    return items
  }

  const daysLeftFunc = async () => {
    const endDate = activeSubscriptionInfo?.trial_end?.toDate().getTime()
    const daysLeft = Math.floor((endDate - time) / (1000 * 60 * 60 * 24))

    if (daysLeft <= 0) {
      setDaysLeft(0)
      setOpenModal(false)
      setOpenProjectModal(false)
    } else {
      setDaysLeft(daysLeft)
    }

    setTimeout(() => {
      setShowPiChart(true)
    }, 2500)
  }

  useEffect(() => {
    daysLeftFunc()
  }, [activeSubscriptionInfo?.trial_end, time])

  return (
    <nav
      className={`${styles.SidebarNav} ${
        isCollapse ? styles.CollapseContainer : ''
      }`}
    >
      <div className={styles.Logo}>
        {!isCollapse && (
          <img src={Logo} alt="Logo" onClick={() => navigate('/')} />
        )}
        <div
          className={styles.Collapse}
          onClick={() => {
            toggleIsCollapse()
          }}
          style={{
            visibility: isCollapse ? 'visible' : 'hidden',
          }}
        >
          {isCollapse ? <SegmentIcon /> : <ChevronLeftIcon />}
        </div>
      </div>
      <div className={styles.MenuContainer}>
        <div className={styles.Menu}>
          {navigation?.items
            ?.at(0)
            .children?.filter((item: any) =>
              customer?.subscriptionType === 'free'
                ? item.id !== 'billing'
                : item
            )
            .map((item: any) => (
              <NavLink
                key={item.id}
                className={({ isActive, isPending }) =>
                  isPending
                    ? styles.MenuItem
                    : isActive
                    ? styles.ActiveMenuItem
                    : styles.MenuItem
                }
                to={item.url}
              >
                {/* {getIcon(item.title)} */}
                <img
                  src={item.icon}
                  alt="Logo"
                  className={styles.Icon}
                  style={
                    location.pathname.includes(item.url) && item.url !== '/'
                      ? {
                          filter: 'brightness(0) invert(1)',
                        }
                      : location.pathname === item.url
                      ? {
                          filter: 'brightness(0) invert(1)',
                        }
                      : {}
                  }
                />
                <span className={isCollapse ? styles.CollapsedMenu : ''}>
                  {item.title}
                </span>
              </NavLink>
            ))}
          {location.pathname.includes('settings') &&
            SettingModals().map((item: any) => (
              <div
                key={item.name}
                className={styles.MenuSettingItem}
                onClick={() => {
                  dispatch(setSettingsModal(item.modal))
                }}
              >
                <div
                  className={styles.Icon}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  {settingsModal === item.modal && (
                    <CircleIcon
                      sx={{
                        color: '#3943b7',
                        width: '.5rem',
                        height: '.5rem',
                      }}
                    />
                  )}
                </div>
                <span
                  className={isCollapse ? styles.CollapsedMenu : ''}
                  style={{
                    color: settingsModal === item.modal ? '#3943b7' : '#788896',
                  }}
                >
                  {item.name}
                </span>
              </div>
            ))}
        </div>

        <div className={styles.Freemium}>
          {!isCollapse && (
            <>
              {activeSubscriptionInfo?.status === 'trialing' &&
              showPiChart &&
              daysLeft ? (
                <>
                  <CustomWidthTooltip
                    title="During this period, you'll have access to all of our premium features and functionality."
                    placement="top"
                  >
                    <StyledBadge
                      color="error"
                      badgeContent={`${daysLeft} \n days left`}
                    >
                      {' '}
                    </StyledBadge>
                  </CustomWidthTooltip>
                  <PieChart
                    width={150}
                    showLegend={false}
                    chartType={'pie'}
                    title="Current Types"
                    chartColors={['#979de5', '#3943b7']}
                    chartData={[
                      { label: 'Day Left:', value: daysLeft },
                      { label: 'Day:', value: 30 - daysLeft },
                    ]}
                  />
                </>
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </div>
      {customer?.subscriptionType === 'free' &&
        !customer?.isTrialUsed &&
        !isCollapse && (
          <div className={styles.FreeTrail}>
            {!openModalFreeTrail && (
              <Button
                fullWidth
                variant="outlined"
                sx={{
                  margin: '.5rem 1rem',
                  color: '#3943b7',
                  borderColor: '#828d9b',
                  textTransform: 'none',
                }}
                onClick={() => {
                  setopenModalFreeTrail(true)
                }}
              >
                Free Trial Mode
              </Button>
            )}
            <FreeTrail
              openModal={openModalFreeTrail}
              setOpenModal={setopenModalFreeTrail}
              customer={customer}
            />
          </div>
        )}
      <Box sx={{ mb: 5, mt: 2 }}>
        <Link underline="none">
          <StyledAccount>
            <Menu
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem
                className={styles.ProfileMenuItem}
                onClick={() => {
                  setAnchorEl(null)
                  navigate('/settings')
                }}
              >
                <AccountCircleIcon sx={{ width: 20, mr: '.5rem' }} />
                <span>{`${customer?.profile?.firstName} ${customer?.profile?.lastName}`}</span>
              </MenuItem>

              <MenuItem
                className={styles.ProfileMenuItem}
                onClick={async () => {
                  await api?.signout()
                  await window.location.reload()
                }}
              >
                <LogoutIcon sx={{ mr: '.5rem', width: 20 }} />
                <span>Logout</span>
              </MenuItem>
            </Menu>
            <ButtonBase
              onClick={(e: any) => setAnchorEl(e.currentTarget)}
              className={styles.ProfileButton}
            >
              <Avatar
                src={customer?.profile?.profilePicURL}
                alt="photoURL"
                sx={{
                  width: 40,
                  height: 40,
                }}
              />
            </ButtonBase>
            {!isCollapse && (
              <Box sx={{ ml: 2 }}>
                <Typography
                  sx={{
                    color: '#1d3557',
                    fontFamily: 'facto, sans-serif !important',

                    fontSize: '1rem !important',
                  }}
                >
                  {customer?.profile?.firstName} {customer?.profile?.lastName}
                </Typography>

                <Typography
                  sx={{
                    color: '#828d9b',
                    fontFamily: 'facto, sans-serif !important',

                    fontSize: '.8rem !important',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {customer?.profile?.position}
                </Typography>
              </Box>
            )}
          </StyledAccount>
        </Link>
      </Box>
    </nav>
  )
}

export default Sidebar
